import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { ApiService } from '../services/api.service';
import { GlobalService } from '../services/global.service';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class LoginAuthGuard implements CanLoad {

  // Inject your authentication service here or use the boolean variable directly.
  constructor(
    private router: Router,
    private apiService: ApiService,
    private globalService: GlobalService
    ) { }
  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    throw new Error('Method not implemented.');
  }

  canActivate(): Observable<boolean> {
    return this.apiService.isAuthenticated.pipe(
      filter(val => val !== null), // Filter out initial Behaviour subject value
      take(1), // Otherwise the Observable doesn't complete!
      map(isAuthenticated => {
        if (isAuthenticated) {
          if(this.globalService.isMobile()){
            this.router.navigate(['/patient-input'])
          }
          else{
              this.router.navigate(['/graphs']);
          }
          return false;
        }
        return true;
      })
    )
  }
}
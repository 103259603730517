//Service that has all the alerts used of the app/website

import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';

@Injectable({providedIn: 'root'})

export class AlertService {

  constructor(
    private alertController: AlertController,
    private toastCtrl: ToastController,
  ) { }

    //The message that will appear if the form that the user submitted is invalid
    async invalidMsg(){
      const alert = await this.alertController.create({
        header: 'Alert!',
        subHeader: 'Invalid Form',
        message: 'Some inputs may be empty or invalid',
        buttons: [
          {
            text: 'OK',
            role: 'confirm',
          },
        ],
      });
      await alert.present();
    }
    
  //The message that will appear if there was an error while signing in
    async errorMsg(err){
      const errorAlert = await this.alertController.create({
        header: 'Error!',
        subHeader: 'Error ' + err.status,
        message: err.error.detail,
        cssClass: 'errorAlert',
        buttons: [
          {
            text: 'OK',
            role: 'confirm',
          },
        ],
      });
      await errorAlert.present()
    }

    //The message that will appear if there was an error while signing in
    async fileError(err){
      const errorAlert = await this.alertController.create({
        header: 'File Error!',
        message: err.toString(),
        cssClass: 'errorAlert',
        buttons: [
          {
            text: 'OK',
            role: 'confirm',
          },
        ],
      });
      await errorAlert.present()
    }

    async userErrorMsg(){
      const errorAlert = await this.alertController.create({
        header: 'Error!',
        message: "Non Global Admins cannot connect into the Desktop version of this Website",
        cssClass: 'errorAlert',
        buttons: [
          {
            text: 'OK',
            role: 'confirm',
          },
        ],
      });
      await errorAlert.present()
    }

    async offlineErrorMsg(){
      const errorAlert = await this.alertController.create({
        header: 'Error!',
        message: "You are offline. Please check your internet connection.",
        cssClass: 'errorAlert',
        buttons: [
          {
            text: 'OK',
            role: 'confirm',
          },
        ],
      });
      await errorAlert.present()
    }

    //The message that will appear if there was an error while signing in
    async firebaseErrorMsg(err){
      const errorAlert = await this.alertController.create({
        header: 'Error!',
        subHeader: 'Error: Firebase',
        message: err.message,
        cssClass: 'errorAlert',
        buttons: [
          {
            text: 'OK',
            role: 'confirm',
          },
        ],
      });
      await errorAlert.present()
    }

  //Message that will appear to confirm if the user wants to quit the app or not
  async quitAppAlert() {
    const alert = await this.alertController.create({
      header: 'Are you sure you want to quit?',
      buttons: [
        { text: 'No' },
        { text: 'Yes',
          handler:() => navigator['app'].exitApp()
        },
      ],
    });
    await alert.present();
  }

  //Message that will appear when an action was succesfully commited
  async successMsg(header,message){
    const createdAlert = await this.alertController.create({
      header: header,
      message: message,
      cssClass: 'successAlert',
      buttons: [
        {
          text: 'OK',
          role: 'confirm',
        },
      ],
    });
  await createdAlert.present();
}

//QR message that will appear once an incident is made
  async qrMsg(qrCodeUrl){
    const alert = await this.alertController.create({
      header: 'INCIDENT CODE GENERATED. SHARE IT WITH THE REST OF THE TEAM.',
      message: `<ion-img src="${qrCodeUrl}"></ion-img>`,
      buttons: ['OK']
    });
    await alert.present();
  }

  //Function that informs the user what button is about to write and how to write the nfc in the app
  async approachNFCMsg(name_btn,canceled){
    const alert = await this.alertController.create({
      header: 'Scan ' + name_btn + ' Category NFC',
      message: 'Approach your NFC tag close to your phone to write data inside. Your phone will vibrate and this message will disappear if writing was successful.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: async () => {
            canceled = true;
          }
        },
      ]
    });  
    await alert.present();
    return canceled;
  }

  //Error message for when ndef attachment has failed
  async ndefAttachErrorMsg(error){
    console.log('error attaching ndef listener', error);
    let toast = await this.toastCtrl.create({
      message: error,
      duration: 1000,
      position: 'bottom'        });
    return await toast.present();
  }

  //Success message when nfc is succesfully written
  async ndefSuccessMsg(msg){
    let toast = await this.toastCtrl.create({
      message: msg + " category has successfully written in NFC tag.",
      duration: 5000,
      position: 'bottom'      
    });
    return await toast.present();
  }
}

import { Injectable } from '@angular/core';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';

import { AlertService } from './alert.service';

@Injectable({providedIn: 'root'})

export class FileService {

  constructor(
    private alertService: AlertService
  ) { }

  //'triage_patients.csv'
  async readFile(path: string){
    return Filesystem.readFile({
      path: path, // Update the path to your CSV file
      directory: Directory.Cache,
      encoding: Encoding.UTF8,
    }).catch((error) => {
      console.log(error)
    });
  }

  async writeFile(path: string, data: string){
    await Filesystem.writeFile({
      path: path, // Update the path to your CSV file
      data: data,
      directory: Directory.Cache,
      encoding: Encoding.UTF8,
    }).catch((error) => {
      this.alertService.fileError(error)
      console.log(error)
    });
  }

  async deleteFile(path: string){
    await Filesystem.deleteFile({
      path: path,
      directory: Directory.Cache,
    }).catch((error) => {
      this.alertService.fileError(error)
      console.log(error)
    });
  }

  async getExistingData(path: string, form: any){
    // Read existing CSV data if any
    let existingRows = '';
    const existingData = await this.readFile(path)

    if (existingData) {
      existingRows = existingData.data as string;
    } else {
      // If the file doesn't exist, create it with a header row
      const headerRow = Object.keys(form.value).join(',');
      existingRows = headerRow;
    }
  
    // Create a new CSV row from the JSON data
    const newRow = Object.values(form.value).join(',');
  
    // Append the new row to the existing rows
    if (existingRows) {
      existingRows += '\n';
    }
    
    existingRows += newRow;
    return existingRows
  }


}

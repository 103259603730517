import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, switchMap, catchError, map } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, throwError} from 'rxjs';
import { Router } from '@angular/router';
import { Preferences } from '@capacitor/preferences';
import { Config } from '@angular/fire/auth/public_api';
import  jwt_decode  from 'jwt-decode';
import { initializeApp } from 'firebase/app';
import { getDoc, getFirestore } from "firebase/firestore";
import { doc, setDoc } from "firebase/firestore"; 
import 'firebase/auth';
import { getAuth, signOut, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, updatePassword, RecaptchaVerifier, signInWithPhoneNumber, ConfirmationResult, Auth, signInWithCustomToken} from "firebase/auth";
import { Network } from '@ionic-native/network/ngx';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';

import { AlertService } from '../services/alert.service';
import { GlobalService } from '../services/global.service';
import { FileService } from '../services/file.service';


const ACCESS_TOKEN_KEY = 'my-access-token';
// const REFRESH_TOKEN_KEY = 'my-refresh-token';

// Initialize Firebase with your project's configuration
const firebaseConfig = {
    apiKey: "AIzaSyDIpN3nPP4x4NXRlXrbxxAsppmSL8AL-vU",
    authDomain: "triage-54c48.firebaseapp.com",
    projectId: "triage-54c48",
    storageBucket: "triage-54c48.appspot.com",
    messagingSenderId: "84847101658",
    appId: "1:84847101658:web:6c184ccb8bf9c85a0c47e3",
    measurementId: "G-TT1YWYT8R8"
  };
  
  
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const extra_data = getFirestore(app);
  let appVerifier: RecaptchaVerifier


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  // Init with null to filter out the first value in a guard!
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  url = environment.baseUrl;


  decodedToken: any;
  name: any;
  email: any;
  password: any;
  phone_number: any;
  database: any;
  role:any;
  user_id: any;

  from_LoginPage = false;
  firebase_token: any;
  currentAccessToken = null;
  rememberMe: any


  constructor(
    private http: HttpClient,
    private router: Router,
    private network: Network,

    private alertService: AlertService,
    private globalService: GlobalService,
    private fileService: FileService
  ){}

   auth = getAuth();

  validationsForm = new UntypedFormGroup({
    'casualty_number': new UntypedFormControl(''),
    'age_id': new UntypedFormControl (''),
    'age': new UntypedFormControl(''),
    'category_id':  new UntypedFormControl(''),
    'category': new UntypedFormControl(''),
    'gps': new UntypedFormControl(''),
  })

 rememberUserData(){
    localStorage.setItem('name',this.name)
    localStorage.setItem('role',this.role );
    localStorage.setItem('database', this.database);
    localStorage.setItem('email',this.email)
    localStorage.setItem('phone_number',this.phone_number)
    localStorage.setItem('user_id',this.user_id)
    localStorage.setItem('token',this.currentAccessToken)
    localStorage.setItem('firebase_token',this.firebase_token)
    localStorage.setItem('rememberMe',this.rememberMe)
  }

  async getSavedUserData(){
    this.name = localStorage.getItem('name')
    this.role = localStorage.getItem('role')
    this.database = localStorage.getItem('database')
    this.email = localStorage.getItem('email')
    this.phone_number = localStorage.getItem('phone_number')
    this.user_id = localStorage.getItem('user_id')
    this.currentAccessToken = localStorage.getItem('token')
    this.firebase_token = localStorage.getItem('firebase_token')
    this.rememberMe = localStorage.getItem('rememberMe')
  }

  unSaveUserData(){
    localStorage.removeItem('name')
    localStorage.removeItem('role')
    localStorage.removeItem('database')
    localStorage.removeItem('email')
    localStorage.removeItem('phone_number')
    localStorage.removeItem('user_id')
    localStorage.removeItem("token")
    localStorage.removeItem("firebase_token")
    localStorage.removeItem('rememberMe');
  }

// Method to verify the email token
async verifyEmailToken(token: string): Promise<boolean> {
  try {
    const decodedToken = jwt_decode(token) as { email: string, exp: number };
    await this.emailTokenUsedOnce(token).toPromise(); // Wait for the token check to complete
    return decodedToken && decodedToken.exp > Date.now() / 1000;
  } catch (error) {
    return false;
  }
}

   emailTokenUsedOnce(token: string){
    return this.http.get<any>(`${environment.baseUrl}/email/verify-token/${token}`);
  }

  async signUpFirebase(email, password, database_name, role, phone_number?: string) {
    try {
      let userCredential: any
      if (role == "Guest"){
         userCredential = await this.signUpnInFirebaseGuest(phone_number)
         const deletionTime = new Date(Date.now() + 24 * 60 * 60 * 1000);

         await setDoc(doc(extra_data, "User_Extra", userCredential.user.uid), {
          Database: database_name,
          Role: 'Guest',
          Deletion_Time : deletionTime
        }) 
      }
      else{
        try{
          userCredential = await createUserWithEmailAndPassword(auth, email, password)
          await setDoc(doc(extra_data, "User_Extra", userCredential.user.uid), {
            Database: database_name,
            Role: role
          }) }

        catch(error){
          console.log("error: "+ error)
          this.alertService.firebaseErrorMsg(error);
          return;
        };


    }

    } catch (error) {
      this.alertService.errorMsg(error)
      throw error;
    }
  }

  async signUpnInFirebaseGuest(phone_number: string) {
    try {
      if (!appVerifier) {
        appVerifier = new RecaptchaVerifier('sign-in-button', {
          size: 'invisible',
          callback: (response) => {}
        }, auth);
      }

      const confirmationResult: ConfirmationResult = await signInWithPhoneNumber(auth, phone_number, appVerifier);
      const verificationCode = window.prompt('Enter the verification code');
      const userCredential = await confirmationResult.confirm(verificationCode);

      return userCredential

    } catch (error) {
      this.alertService.errorMsg(error)
      throw error;
    }
  }

  login(credentials: {email: string, password: string}): Promise<void> {
    this.email = credentials.email;
    this.password = credentials.password;
    return new Promise<void>((resolve, reject) => {
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then(() => {
          this.authenticate(this.email,this.rememberMe,"NonGuest").pipe(
            switchMap(async (tokens: {access_token: string, firebase_token: string, token_type: string}) => {

              this.currentAccessToken = tokens.access_token;
              this.firebase_token = tokens.firebase_token
              this.decodedToken = jwt_decode(this.currentAccessToken);
              this.user_id = this.decodedToken.user_id
              const snapshot = await getDoc(doc(extra_data, "User_Extra", this.user_id));
              const data =  snapshot.data()
              this.database = data.Database
              this.role = data.Role

              this.changeName().subscribe(name => {
                this.name = name;
              });
              this.phone_number = null

              if (this.role != "Global Admin" && !this.globalService.isMobile()){
                this.alertService.userErrorMsg()
                this.isAuthenticated.next(false);
                this.logout()
                return
              }

              this.rememberUserData();
              this.connectDatabase(this.database).subscribe(); 
              const storeAccess = Preferences.set({key: ACCESS_TOKEN_KEY, value: tokens.access_token});
              // const storeRefresh = Storage.set({key: REFRESH_TOKEN_KEY, value: tokens.refreshToken});
              this.loginSuccess();

              return from(Promise.all([storeAccess]));  
            }),
            tap(_ => {
              this.isAuthenticated.next(true);
              resolve(); // Resolve the outer promise when the inner Observable completes
            })
          ).subscribe(); // Subscribe to start the inner Observable
        })
        .catch((error) => {
          if (this.globalService.isOffline()) {
            this.alertService.offlineErrorMsg();
          }
          else {
            this.alertService.firebaseErrorMsg(error);
          }
          this.isAuthenticated.next(false);
          reject(error);
        });
    });
  }

  loginGuest(phone_number): Promise<void> {
    this.phone_number = phone_number
    return new Promise<void>((resolve,reject) => {
      this.signUpnInFirebaseGuest(this.phone_number)
      .then(() => {
        this.authenticate(this.phone_number,this.rememberMe,"Guest").pipe(
          switchMap(async (tokens: {access_token: string, firebase_token: string, token_type: string}) => {
            this.currentAccessToken = tokens.access_token;
            this.firebase_token = tokens.firebase_token
            this.decodedToken = jwt_decode(this.currentAccessToken);
            this.user_id = this.decodedToken.user_id
            const snapshot = await getDoc(doc(extra_data, "User_Extra", this.user_id));
            const data =  snapshot.data()
            this.database = data.Database
            this.role = data.Role

            this.changeName().subscribe(name => {
              this.name = name;
            });
            this.email = null

            this.rememberUserData();
            this.connectDatabase(this.database).subscribe(); 
            const storeAccess = Preferences.set({key: ACCESS_TOKEN_KEY, value: tokens.access_token});
            this.loginSuccess();
            return from(Promise.all([storeAccess]));  
          }),
        tap(_ => {
          this.isAuthenticated.next(true);
          resolve(); // Resolve the outer promise when the inner Observable completes
        })
        ).subscribe();
      }).catch((error) => {
        if (this.globalService.isOffline()) {
          this.alertService.offlineErrorMsg();
        }
        else {
          console.log(error)
          this.alertService.firebaseErrorMsg(error);
        }
          this.isAuthenticated.next(false);
          reject(error);
        })
    });
  }

  loginWithToken(){
    this.getSavedUserData();
    const targetUrl = new URL(window.location.href);
    if (this.firebase_token && (this.rememberMe == "true" || performance.navigation.type === 1)){
      this.decodedToken = jwt_decode(this.firebase_token);
      if (!this.globalService.isOffline()){
        signInWithCustomToken(auth,this.firebase_token).then(() => {
          // Signed in
          if (this.role != "Global Admin" && !this.globalService.isMobile()){
            this.alertService.userErrorMsg()
            this.isAuthenticated.next(false);
            this.logout()
            return
          }
          this.changeName().subscribe(name => {
            this.name = name;
          });
          this.connectDatabase(this.database).subscribe(); 
          const storeAccess = Preferences.set({key: ACCESS_TOKEN_KEY, value: this.currentAccessToken});
          this.loginSuccess();
          return from(Promise.all([storeAccess])); 
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(errorCode + ": " + errorMessage)
        });
      }
      this.isAuthenticated.next(true);      
    }
    else{
      this.isAuthenticated.next(false);
      //this.logout()
    }
  }

  fromLoginPage(){
    this.from_LoginPage = true
  }

  changeName(): Observable<string> {
    return this.getUserData(this.user_id).pipe(
      map(data => data.name + " " + data.surname + " - " + this.role)
    );
  }

  async loginSuccess() {
    const firebase_id = this.user_id
    this.getSavedUserData()

    this.getUserData(firebase_id).subscribe(data => {
      
      if (this.from_LoginPage || this.role == "Guest"){
        if (this.globalService.isMobile())
          this.router.navigate(['/patient-input'], {replaceUrl: true})
         else {
          this.router.navigate(['/graphs'], {replaceUrl: true})
       }
      }
    },
    error => {
      if (error.status === 404) {
        this.router.navigate(['/add-credentials'], { replaceUrl: true });
       }
    });

    if (this.globalService.isMobile){
      try {
        // Read existing CSV data if any
        const path = "triage_patients_" + this.user_id +".csv"
        const existingData = await this.fileService.readFile(path);

        if (existingData) {
          const existingRows = existingData.data as string;
          const rows = existingRows.split('\n');
      
          // Iterate over each row (skip the header row)
          for (let i = 1; i < rows.length; i++) {
            const rowData = rows[i].split(',');
            const jsonData = {}; // Create an empty JSON object for each row
      
            // Assuming the order of columns in the CSV matches the order in your JSON
            Object.keys(this.validationsForm.value).forEach((key, index) => {
              jsonData[key] = rowData[index];
            });

            this.createRecord(jsonData).subscribe({
              next: async _ => {
                console.log('Upload Success!', jsonData);
              },
              error: async error => {
                console.error('Upload Error:', error);
              },
            });
          }
          this.fileService.deleteFile(path);
          this.alertService.successMsg("Upload Success","Data processed and uploaded successfully!")
          console.log('Data processed and uploaded successfully.');
        } 
      } catch (error) {
        this.alertService.errorMsg(error)
        console.error('Error processing and uploading data:', error);
      }
    }
  }

  forgotPasswordFirebase(email: string,){
    sendPasswordResetEmail(this.auth, email)
    .catch((error) => {
      this.alertService.firebaseErrorMsg(error)
    });
  }

  // Potentially perform a logout operation inside your API
  // or simply remove all local tokens and navigate to login
  logout() {
    signOut(auth).then(() => {
      Preferences.remove({ key: ACCESS_TOKEN_KEY });
      this.isAuthenticated.next(false);
      this.unSaveUserData();
      if (this.role == "Guest")
      this.router.navigateByUrl('/auth/invite-login', { replaceUrl: true });
      else
        this.router.navigateByUrl('/', { replaceUrl: true });
    }).catch((error) => {
      console.log(error)
    });
  }

  authenticate(auth_key: string, rememberMe: boolean, role: string): Observable<any>{
   
    let body = {
      auth_key : auth_key,
      role : role,
      rememberMe: rememberMe
    }
    return this.http.post(`${this.url}/authenticate/`, body)
  }

  createRecord(form_value): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.currentAccessToken}`);

    let body = {
      casualty_number: form_value.casualty_number,
      category_id: form_value.category_id,
      age_id: form_value.age_id,
      coordinates: "POINT(" + form_value.gps + ")"
    }
    return this.http.post(`${environment.baseUrl}/records/`, body, {headers})
  }
  
  deleteRecord(form_value): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.currentAccessToken}`);
    return this.http.delete(`${environment.baseUrl}/records/${parseInt(form_value.casualty_number)}`, {headers})
  }

  updateRecord(form_value,id): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.currentAccessToken}`);

    let body = {
      casualty_number: form_value.casualty_number,
      incident_id: form_value.incident,
      category_id: form_value.category_id,
      age_id: form_value.age_id,
      coordinates: "POINT(" + form_value.gps + ")",
    }
    return this.http.put(`${environment.baseUrl}/records/${id}`, body, {headers})
  }

  // Create new user
  createUser(form_value): Observable<any> {
    let body = {
      firebase_id: form_value.firebase_id,
      name: form_value.name,
      surname: form_value.surname,
      specialities: form_value.speciality ,
      email: form_value.email,
      role: form_value.role
    }
    return this.http.post(`${this.url}/users`, body);
  }

  createGuest(form_value): Observable<any>{
    let body = {
      firebase_id: form_value.firebase_id,
      name: form_value.name,
      surname: form_value.surname,
      specialities: form_value.speciality ,
      phone_number: form_value.phone_number,
      role: form_value.role
    }
    return this.http.post(`${this.url}/users/invite-guest`, body);
  }

  updateUser(form_value:any, id:string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.currentAccessToken}`);

    let body = {
      name: form_value.name,
      surname: form_value.surname,
      specialities: form_value.speciality,
      email: form_value.email,
    }
    return this.http.put(`${environment.baseUrl}/users` + id, body, {headers})
  }

  async changePassword(password: string) {
      const user = this.auth.currentUser;
      updatePassword(user, password).then(() => {
        const header = 'Change Success!';
        const message = 'Password have been changed.'
        this.alertService.successMsg(header,message)
      }).catch((error) => {
        console.error('Error changing password:', error);
      });
    }
    

  getSpecialtyData(){
    return this.http.get<any>(`${environment.baseUrl}/specialities`)
  }

  getUsersData(){
    return this.http.get<Config>(`${environment.baseUrl}/users?limit=100`)
  }

  getGuestUsers(){
    return this.http.get<Config>(`${environment.baseUrl}/users/guests?limit=100`)
  }

  getUserData(id:string){
    return this.http.get<any>(`${environment.baseUrl}/users/${id}`);
  }

  getUserTeam(){
    return this.http.get<any>(`${environment.baseUrl}/users/${this.user_id}/team`);
  }

  getUserIncident(){
    return this.http.get<any>(`${environment.baseUrl}/users/${this.user_id}/incident`);
  }

  getAvailableUsers(){
    return this.http.get<Config>(`${environment.baseUrl}/users/available?limit=100`)
  }

  getAvailableUsersWithID(){
    return this.http.get<Config>(`${environment.baseUrl}/users/availableWithID?limit=100`)
  }

  getTeamAvailableUsers(id){
    return this.http.get<Config>(`${environment.baseUrl}/teams/${id}/available_users?limit=100`)
  }

  sendUserVerificationEmail(email:string, password: string): Observable<any>{
    let body = {
      receiver: email,
      password_receiver: password,
    }
    return this.http.post(`${this.url}/email/user_verification`, body)
  }

  sendTenantEmail(form_value): Observable<any>{
    let body = {
      org_number: form_value.org_number,
      org_name: form_value.org_name,
      name: form_value.name,
      surname:form_value.surname,
      phone_number: form_value.phone_number,
      email: form_value.email,
      password: form_value.matching_passwords.password
    }
    return this.http.post(`${this.url}/email/send_to_tenant`, body)
  }
  
  decodeAdminData(encoded_email,encoded_password,encoded_database,encoded_key,choice): Observable<any>{
    let body = {
      email: encoded_email,
      password: encoded_password,
      database: encoded_database,
      key: encoded_key,
      choice: choice
    }
    return this.http.post(`${this.url}/email/admin_data_decoding`, body)
  }

  sendAdminVerificationEmail(email, choice){
    let body = {
      receiver: email,
      choice: choice
    }
    return this.http.post(`${this.url}/email/process_tenant_choice`, body)
  }

  getRecordData(){
    return this.http.get<Config>(`${environment.baseUrl}/records?limit=100`)
  }

  getRecordDataGraph() {  
    return this.http.get<Config>(`${environment.baseUrl}/records/graph?limit=100`);
  }
  

  getIncidentData(){
    return this.http.get<Config>(`${environment.baseUrl}/incidents?limit=100`)
  }

  getIncidentNames(){
    return this.http.get<Config>(`${environment.baseUrl}/incidents/name?limit=100`)
  }

  getIncidentIDNameDescription(id){
    return this.http.get<any>(`${environment.baseUrl}/incidents/${id}`)

  }

  getIncidentDataView(id){
    return this.http.get<any>(`${environment.baseUrl}/incidents/${id}/details`)
  }

  getIncidents(){
    return this.http.get<Config>(`${environment.baseUrl}/incidents/?limit=100`)
  }

  getIncidentCategoryCount(){
    return this.http.get<Config>(`${environment.baseUrl}/incidents/categories_count/?limit=100`)
  }

  getIncidentRecords(id){
    return this.http.get<Config>(`${environment.baseUrl}/incidents/${id}/records`)
  }

  createIncident(form_value): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.currentAccessToken}`);

    let body = {
      group_id: form_value.group,
      name: form_value.incidentName,
      description: form_value.incidentDescription,
    }
    return this.http.post(`${environment.baseUrl}/incidents`, body, {headers})
  }

  updateIncident(form_value): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.currentAccessToken}`);

    let body = {
      id: form_value.incidentID,
      name: form_value.incidentName,
      group_id: form_value.group,
      description: form_value.incidentDescription,
    }
    return this.http.put(`${environment.baseUrl}/incidents/${form_value.incidentID}`, body, {headers})
  }

  closeIncident(id): Observable<any>{
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.currentAccessToken}`);

    return this.http.put(`${environment.baseUrl}/incidents/${id}/active_change`, "", {headers})
  }

  deleteIncident(id) : Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.currentAccessToken}`);

    return this.http.delete(`${environment.baseUrl}/incidents/${id}`,{headers})
  }

  getTeamsData(){
    return this.http.get<Config>(`${environment.baseUrl}/teams?limit=100`)
  }

  getTeamDataView(id){
    return this.http.get<any>(`${environment.baseUrl}/teams/${id}`)
  }

  createTeam(form_value) : Observable<any>{
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.currentAccessToken}`);

    let body = {
      name: form_value.name,
      incident_id: form_value.incident_id,
      members: form_value.members
    }
    return this.http.post(`${environment.baseUrl}/teams`, body, {headers})
  }

  updateTeam(form_value): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.currentAccessToken}`);

    let body = {
      incident_id: form_value.incident_id,
      name: form_value.name,
      members: form_value.members

    }
    return this.http.put(`${environment.baseUrl}/teams/${form_value.id}`, body, {headers})
  }

  deleteGroup(group_id) : Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.currentAccessToken}`);

    return this.http.delete(`${environment.baseUrl}/groups/${group_id}`,{headers})
  }

  getTeamRoles(){
    return this.http.get<Config>(`${environment.baseUrl}/roles/team_roles`)
  }

  getPowerBIGraphData(db_name){

    let body = {
      name: "abc_123"
    }
    return this.http.post<Config>(`${this.url}/get_pbi_graph_data`, body)
  }

  createDatabase(db_name): Observable<any> {

    let body = {
      name: db_name
    }
    return this.http.post(`${this.url}/database/create_database`, body);
  }

  connectDatabase(db_name): Observable<any>{

    let body = {
      name: db_name
    }
    return this.http.post(`${this.url}/database/connect_database`, body);
  }
}
import { NgModule } from '@angular/core';
import { PreloadAllModules, Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { MobileAuthGuard } from './guards/mobileAuth.guard';
import { AdminAuth } from './guards/adminAuth.guard'
import { LoginAuthGuard } from './guards/loginAuth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    canActivate: [LoginAuthGuard], // Use the new guard here
    children: [
        {
          path: '',
          redirectTo: 'login', // Redirect to home page
          pathMatch: 'full'
        },
        {
          path: 'login',
          loadChildren: () => import('./auth/login/login.module').then(m => m.LoginPageModule)
        },
        {
          path: 'invite-login',
          loadChildren: () => import('./auth/invite-login/invite-login.module').then(m => m.InviteLoginPageModule),
          canLoad: [MobileAuthGuard]
        },
        {
          path: 'register',
          loadChildren: () => import('./auth/register/register.module').then(m => m.RegisterPageModule),
        },
        {
          path: 'register/thank-you',
          loadChildren: () => import('./auth/thank-you/thank-you.module').then(m => m.ThankYouPageModule),
        },
        {
          path: 'register/eimail-has-been-sent',
          loadChildren: () => import('./auth/email-sent/email-sent.module').then(m => m.EmailSentModule),
        },
        {
          path: 'forgot-password',
          loadChildren: () => import('./auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
        },    
    ]
  },  
  {
    path: 'add-credentials',
    loadChildren: () => import('./profile/add-credentials/add-credentials.module').then(m => m.AddCredentialsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'change-password',
    loadChildren: () => import('./profile/change-password/change-password.module').then(m => m.ChangePasswordPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'change-credentials',
    loadChildren: () => import('./profile/change-credentials/change-credentials.module').then(m => m.ChangeCredentialsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'patient-input',
    loadChildren: () => import('./patient/input-patient/input-patient.module').then(m => m.FormsInputPatientPageModule),
    canLoad: [MobileAuthGuard]
  },
  {
    path: 'nfc-output',
    loadChildren: () => import('./output-nfc/output-nfc.module').then(m => m.OutputNFCPageModule),
    canLoad: [MobileAuthGuard]
  },
  {
    path: 'patient-list',
    loadChildren: () => import('./patient/patient-list/patient-list.module').then(m => m.PatientListPageModule),
    canLoad: [AdminAuth]
  },
  {
    path: 'user-list',
    loadChildren: () => import('./user/user-list/user-list.module').then(m => m.UserListPageModule),
    canLoad: [AdminAuth]
  },
  {
    path: 'user-invite',
    loadChildren: () => import('./user/user-invite/user-invite.module').then(m => m.UserInvitePageModule),
    canLoad: [AdminAuth]
  },
  {
    path: 'guest-list',
    loadChildren: () => import('./guest/guest-list/guest-list.module').then(m => m.GuestListPageModule),
    canLoad: [AdminAuth]
  },
  {
    path: 'guest-invite',
    loadChildren: () => import('./guest/guest-invite/guest-invite.module').then(m => m.GuestInvitePageModule),
  },
  {
    path: 'incident-list',
    loadChildren: () => import('./incident/incident-list/incident-list.module').then(m => m.IncidentListPageModule),
    canLoad: [AdminAuth]
  },
  {
    path: 'generate-incident',
    loadChildren: () => import('./incident/generate-incident/generate-incident-test.module').then(m => m.GenerateIncidentListPageModule),
    canLoad: [AdminAuth]
  },
  {
    path: 'edit-incident',
    loadChildren: () => import('./incident/edit-incident/edit-incident.module').then(m => m.EditIncidentPageModule),
    canLoad: [AdminAuth]
  },
  {
    path: 'team-list',
    loadChildren: () => import('./teams/team-list/team-list.module').then(m => m.TeamListPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'incident-view',
    loadChildren: () => import('./incident/incident-view/incident-view.module').then(m => m.IncidentViewPageModule),
    canLoad: [AdminAuth]
  },
  {
    path: 'generate-team',
    loadChildren: () => import('./teams/generate-team/generate-team.module').then(m => m.GenerateTeamPageModule),
    canLoad: [AdminAuth]
  },
  {
    path: 'edit-team',
    loadChildren: () => import('./teams/edit-team/edit-team.module').then(m => m.EditTeamPageModule),
    canLoad: [AdminAuth]
  },
  {
    path: 'team-view',
    loadChildren: () => import('./teams/team-view/team-view.module').then(m => m.TeamViewPageModule),
    canLoad: [AdminAuth]
  },
  {
    path: 'user-profile',
    loadChildren: () => import('./profile/user-profile/user-profile.module').then(m => m.UserProfilePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'guest-profile',
    loadChildren: () => import('./profile/guest-profile/guest-profile.module').then(m => m.GuestProfilePageModule),
    canLoad: [MobileAuthGuard]
  },
  {
    path: 'page-not-found',
    loadChildren: () => import('./page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
  },
  {
    path: 'maps',
    loadChildren: () => import('./maps/maps.module').then(m => m.MapsPageModule),
    canLoad: [AdminAuth]
  },
  {
    path: "graphs",
    loadChildren: () => import("./graphs/graphs.module").then(m => m.GraphsModule),
    canLoad: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'page-not-found'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // This value is required for server-side rendering to work.
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabledNonBlocking',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

<ion-app dir="{{textDir}}">
  <!-- when=false means that the split pane will never expand automatically -->
  <!-- For more info check https://ionicframework.com/docs/api/split-pane -->
  <ion-split-pane contentId="menu-content" when="false" side="end">
    <ion-menu contentId="menu-content" class="app-sidemenu" side="end">
      <ion-header>
        <ion-toolbar>
          <ion-row class="user-details-wrapper">
            <ion-col size="4">
              <app-aspect-ratio [ratio]="{w: 1, h: 1}">
                <app-image-shell class="user-avatar" animation="spinner" [src]="'./assets/sample-images/avatar2.png'"></app-image-shell>
              </app-aspect-ratio>
            </ion-col>
            <ion-col class="user-info-wrapper" size="8">
              <h3 class="user-name">{{name}}</h3>
              <h5 *ngIf="email !== 'null'" class="user-handle">{{email}}</h5>
              <h5 *ngIf="phone_number !== 'null'" class="user-handle">{{phone_number}}</h5>
            </ion-col>
          </ion-row>
          <ion-row class="user-stats-wrapper user-details-wrapper">
          </ion-row>
        </ion-toolbar>
      </ion-header>

      <ion-content>
        
      <div *ngIf="globalService.isMobile()">
        
        <ion-list>
          <ion-list-header>
            <ion-label>Menu</ion-label>
          </ion-list-header>

          <ion-menu-toggle autoHide="false">
            <ion-item [routerLink]="triagePatient.url">
              <ion-icon slot="start" [name]="triagePatient.ionicIcon"></ion-icon>
              <ion-label>{{ triagePatient.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <div *ngIf="apiService.role !== 'Guest'">
            <ion-menu-toggle autoHide="false" *ngFor="let p of nonGuestAppPages; let i = index">
              <ion-item [routerLink]="p.url" *ngIf="p.title !== 'Graphs' || !offline">
                <ion-icon slot="start" [name]="p.ionicIcon ? p.ionicIcon : ''" [src]="p.customIcon ? p.customIcon : ''"></ion-icon>
                <ion-label>
                  {{p.title}}
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </div>

        <div *ngIf = "offline == false">
          <div *ngIf="apiService.role !== 'Guest' && apiService.role !== 'User'">
            <ion-menu-toggle autoHide="false" *ngFor="let p of appPages; let i = index">
              <ion-item [routerLink]="p.url">
                <ion-icon slot="start" [name]="p.ionicIcon? p.ionicIcon: ''" [src]="p.customIcon? p.customIcon: ''"></ion-icon>
                <ion-label>
                  {{p.title}}
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </div>

          <ion-list *ngIf="apiService.role !== 'Guest' && apiService.role !== 'User'">
            <ion-list-header>
              <ion-label>Command Net</ion-label>
            </ion-list-header>

              <ion-menu-toggle autoHide="false" *ngFor="let p of commandPages; let i = index">
                <ion-item [routerLink]="p.url">
                  <ion-icon slot="start" [name]="p.ionicIcon? p.ionicIcon: ''" [src]="p.customIcon? p.customIcon: ''"></ion-icon>
                  <ion-label>
                    {{p.title}}
                  </ion-label>
                </ion-item>
              </ion-menu-toggle>
          </ion-list>
        </div>

        <ion-list>
          <ion-list-header> 
            <ion-label>Account</ion-label>
          </ion-list-header>
          
          <div *ngIf = "offline == false">
            <div *ngIf="apiService.role !== 'Guest'">
              <ion-menu-toggle autoHide="false">
                <ion-item [routerLink]="accountPage.url">
                  <ion-icon slot="start" [name]="accountPage.ionicIcon"></ion-icon>
                  <ion-label>{{ accountPage.title }}</ion-label>
                </ion-item>
              </ion-menu-toggle>
            </div>

            <div *ngIf="apiService.role === 'Guest'">
              <ion-menu-toggle autoHide="false">
                <ion-item [routerLink]="guestProfile.url">
                  <ion-icon slot="start" [name]="guestProfile.ionicIcon"></ion-icon>
                  <ion-label>{{ guestProfile.title }}</ion-label>
                </ion-item>
              </ion-menu-toggle>
            </div>
          </div>
        </ion-list>

          <ion-item button (click)="logout()">
            <ion-icon slot="start" name="log-out-outline" src = "log-out-outline"></ion-icon>
            <ion-label>
              Log Out
            </ion-label>
          </ion-item>
      </ion-list>

      </div>

      <div *ngIf="!this.globalService.isMobile()">
        <ion-list>
          <ion-list-header>
            <ion-label>Reports</ion-label>
          </ion-list-header>

          <ion-menu-toggle autoHide="false" *ngFor="let p of reportPages; let i = index">
            <ion-item [routerLink]="p.url">
              <ion-icon slot="start" [name]="p.ionicIcon? p.ionicIcon: ''" [src]="p.customIcon? p.customIcon: ''"></ion-icon>
              <ion-label>
                {{p.title}}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

        <ion-list>
          <ion-list-header>
            <ion-label>User Settings</ion-label>
          </ion-list-header>
          <ion-menu-toggle autoHide="false" *ngFor="let p of userSettings; let i = index">
            <ion-item [routerLink]="p.url">
              <ion-icon slot="start" [name]="p.ionicIcon? p.ionicIcon: ''" [src]="p.customIcon? p.customIcon: ''"></ion-icon>
              <ion-label>
                {{p.title}}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-item button (click)="logout()">
            <ion-icon slot="start" name="log-out-outline" src = "log-out-outline"></ion-icon>
            <ion-label>
              Log Out
            </ion-label>
          </ion-item>
        </ion-list>

      </div>

      </ion-content>
    </ion-menu>
    <!-- Main app content gets rendered in this router-outlet -->
    <ion-router-outlet id="menu-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>

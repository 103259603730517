import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router } from '@angular/router';
import { AuthGuard } from './auth.guard';

import { GlobalService } from '../services/global.service';

@Injectable({
  providedIn: 'root'
})
export class MobileAuthGuard implements CanLoad {
  constructor(private authGuard: AuthGuard, private globalService: GlobalService, private router: Router) {}

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    if (this.authGuard.canLoad() && this.globalService.isMobile()) {
      return true;
    }

    this.router.navigate(['/123']); //For now it will show a not found page, later it will be a page that tells the user that they cannot enter
    return false;
  }
}
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Network } from '@ionic-native/network/ngx';

@Injectable({providedIn: 'root'})

export class GlobalService {

  constructor(
    private platform: Platform,
    private network: Network
  ) { }
  

  public isMobile(): boolean {
    return (this.platform.is("android") || this.platform.is("ios"))
  }

  public isDesktop(): boolean{
    return (this.platform.is("desktop"))
  }

  public isTablet(): boolean{
    return (this.platform.is("tablet"))
  }

  public isOffline(): boolean{
    if (this.network.type === "none"){
      console.log("Network is offline");
      return true
    }
    else{
      console.log("Network is online");
      return false
    }
  }

}

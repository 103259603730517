import { Component, DoCheck, HostListener, OnInit } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { TranslateService } from '@ngx-translate/core';
import { HistoryHelperService } from './utils/history-helper.service';
import { MenuController} from '@ionic/angular';
import { register } from 'swiper/element/bundle';

import { GlobalService } from '././services/global.service';
import { ApiService } from './services/api.service';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: [
    './side-menu/styles/side-menu.scss',
    './side-menu/styles/side-menu.shell.scss',
    './side-menu/styles/side-menu.responsive.scss'
  ],
  //changeDetection: ChangeDetectionStrategy.OnPush

})
export class AppComponent implements DoCheck,OnInit {

  name: string;
  email: string;
  phone_number: string;
  user_id: any;
  offline: boolean;

  triagePatient = {
      title: 'Triage Patient',
      url: '/patient-input',
      ionicIcon: 'create-outline',
  }

  nonGuestAppPages=[
    {
      title: 'Program NFC',
      url: '/nfc-output',
      ionicIcon: 'pencil',
    },
    {
      title: 'Graphs',
      url: '/graphs',
      ionicIcon: 'pie-chart-outline',
    },
  ]

  appPages = [
    {
      title: 'Incidents',
      url: '/incident-list',
      ionicIcon: 'list',
    },
    {
      title: 'Teams',
      url: '/team-list',
      ionicIcon: 'people',
    },
    {
      title: 'Patients',
      url: '/patient-list',
      ionicIcon: 'list',
    },
    {
      title: 'Users',
      url: '/user-list',
      ionicIcon: 'list',
    },
    {
      title: 'Invite Guests',
      url: '/guest-list',
      ionicIcon: 'person-add',
    } 
  ];

  commandPages  = [
    {
      title: 'Graphs',
      url: '/graphs',
      ionicIcon: 'pie-chart-outline',
    },
    {
      title: 'Map',
      url: '/maps',
      ionicIcon: 'map-outline',
    } 
  ]

  reportPages = [
    {
      title: 'Graphs',
      url: '/graphs',
      ionicIcon: 'pie-chart-outline',
    },
    {
      title: 'Patients',
      url: '/patient-list',
      ionicIcon: 'list',
    },
    {
      title: 'Incidents',
      url: '/incident-list',
      ionicIcon: 'list',
    },
    {
      title: 'Map',
      url: '/maps',
      ionicIcon: 'map-outline',
    } 
  ]

  userSettings = [
    {
      title: 'Teams',
      url: '/team-list',
      ionicIcon: 'people',
    },
    {
      title: 'Users',
      url: '/user-list',
      ionicIcon: 'list',
    },
    {
      title: 'Invite Guests',
      url: '/guest-list',
      ionicIcon: 'person-add',
    },
    {
      title: 'User Profile',
      url: '/user-profile',
      ionicIcon: 'person-circle-outline'
    },
  ]

  guestProfile = {
    title: 'Guest Profile',
    url: '/guest-profile',
    ionicIcon: 'person-circle-outline'
  }

  accountPage = {
      title: 'User Profile',
      url: '/user-profile',
      ionicIcon: 'person-circle-outline'
    }

  buttons = [
    {
      title: 'Log Out',
      ionicIcon: 'log-out-outline'
    },
  ];

  textDir = 'ltr';
  previous_user_id: any;

  // Inject HistoryHelperService in the app.components.ts so its available app-wide
  constructor(
    public translate: TranslateService,
    public historyHelper: HistoryHelperService,
    public menu: MenuController,
    private apiService: ApiService,
    public globalService: GlobalService
  ) {
    this.initializeApp();
  }
  
  ngOnInit() {
    this.apiService.loginWithToken()
    this.offline = this.globalService.isOffline()
  }

  async ngDoCheck() {
      this.user_id = this.apiService.user_id;
      this.name = this.apiService.name
      this.email = this.apiService.email
      this.phone_number = this.apiService.phone_number
    }

  async initializeApp() {
    try {
      await SplashScreen.hide();
    } catch (err) {
      console.log('This is normal in a browser', err);
    }
  }

  logout(){
    this.menu.enable(false)
    this.apiService.logout()
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {

    if (this.apiService.rememberMe === "false" && performance.navigation.type !== 1){
          $event.returnValue = "Are you sure you want to leave?"; // This line is required to show the browser's confirmation dialog
          if ($event.returnValue  === false){
            this.logout(); // Call your logout function
          }
        }
    }

}
